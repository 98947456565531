import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import CommonMixin from "@/core/plugins/common-mixin";
import { Calendar } from "@fullcalendar/core";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"; // import JwtService from "@/core/services/jwt.service";

import APIService from "@/core/services/api.service";
import { toSafeInteger, isEmpty } from "lodash";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "resource-calendar",
  mixins: [CommonMixin],
  components: {
    Barcode: Barcode,
    Datepicker: Datepicker,
    DetailTemplate: DetailTemplate,
    SingleVisitDetail: SingleVisitDetail
  },
  data: function data() {
    return {
      draggableEvent: null,
      calendar: null,
      startDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().endOf("day").format("YYYY-MM-DD"),
      dragged: {},
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      statusLoading: false,
      statusList: [],
      status: null,
      customerLoading: false,
      customerList: [],
      customer: [],
      engineerLoading: false,
      engineerList: [],
      engineer: [],
      visit: [],
      visitId: null,
      eventClickedDialog: false,
      timeout: null,
      timeoutLimit: 500
    };
  },
  methods: {
    setDefaultDate: function setDefaultDate(param) {
      if (param) {
        this.defaultDate = param;

        if (this.calendar) {
          this.calendar.gotoDate(param);
        }
      }
    },
    reloadCalendar: function reloadCalendar() {
      var _this = this;

      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.removeAllEvents();

        _this.refetchEvents();

        _this.refetchResources();

        _this.getUnassignedVisits();
      }, _this.timeoutLimit);
    },
    refetchEvents: function refetchEvents() {
      this.calendar.refetchEvents();
    },
    refetchResources: function refetchResources() {
      this.calendar.refetchResources();
    },
    removeAllEvents: function removeAllEvents() {
      this.calendar.removeAllEvents();
    },
    initCalendar: function initCalendar() {
      var calendarEl = document.getElementById("bt-calendar");
      this.calendar = new Calendar(calendarEl, {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        plugins: [resourceTimeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev today next",
          center: "title",
          right: "resourceTimeGridDay,resourceTimeGridThreeDay"
        },
        aspectRatio: 2.2,
        initialView: "resourceTimeGridDay",
        views: {
          resourceTimeGridThreeDay: {
            type: "resourceTimeGrid",
            duration: {
              days: 3
            },
            buttonText: "3 days"
          }
        },
        editable: true,
        selectable: true,
        droppable: true,
        nowIndicator: true,
        eventResize: this.eventResized,
        eventDrop: this.eventDropped,
        eventClick: this.eventClicked,
        datesSet: this.dateChanged,
        drop: this.dropped,
        resources: this.getResources,
        events: this.getEvents
      });
      this.calendar.render();
    },
    eventResized: function eventResized(param) {
      var _this2 = this;

      try {
        var event = param.event;
        var extended_props = event.extendedProps;
        var params = {
          visit: extended_props.visitId,
          started_at: event.startStr.substr(0, 19),
          finished_at: event.endStr.substr(0, 19)
        };
        APIService.patch("resource-calendar/event-resized", params).catch(function (error) {
          _this2.logError(error);
        }).finally(function () {
          _this2.refetchEvents();
        });
      } catch (error) {
        this.logError(error);
        param.revert();
      }
    },
    eventDropped: function eventDropped(param) {
      var _this3 = this;

      try {
        var event = param.event;
        var new_resource = param.newResource;
        var old_resource = param.oldResource;
        var extended_props = event.extendedProps;
        var is_all_day = toSafeInteger(event.allDay);
        var params = {
          all_day: is_all_day,
          date: is_all_day ? event.startStr.substr(0, 19) : null,
          visit: extended_props.visitId,
          engineer: new_resource.id,
          del_engineer: old_resource.id,
          started_at: is_all_day ? null : event.startStr.substr(0, 19),
          finished_at: is_all_day ? null : event.endStr.substr(0, 19)
        };

        if (!event.allDay && !event.end) {
          params.finished_at = moment(event.startStr).add(1, "hour").format("YYYY-MM-DDTHH:mm:ss");
        }

        APIService.patch("resource-calendar/event-dropped", params).catch(function (error) {
          _this3.logError(error);
        }).finally(function () {
          _this3.refetchEvents();
        });
      } catch (error) {
        this.logError(error);
        param.revert();
      }
    },
    eventClicked: function eventClicked(param) {
      var event = param.event;

      if (event) {
        var extended_props = event.extendedProps;
        this.visitId = extended_props.visitId;
        this.eventClickedDialog = true;
      }
    },
    getResources: function getResources(fetchInfo, successCallback, failureCallback) {
      APIService.query("resource-calendar/resources", {
        engineers: this.engineer
      }).then(function (_ref) {
        var data = _ref.data;
        successCallback(data);
      }).catch(function (error) {
        failureCallback(error);
      });
    },
    getEvents: function getEvents(fetchInfo, successCallback, failureCallback) {
      var params = {
        start: moment(fetchInfo.start).format("YYYY-MM-DDTHH:mm"),
        end: moment(fetchInfo.end).format("YYYY-MM-DDTHH:mm"),
        engineers: this.engineer,
        customers: this.customer,
        statuses: this.status
      };
      APIService.query("resource-calendar/events", params).then(function (_ref2) {
        var data = _ref2.data;
        successCallback(data);
      }).catch(function (error) {
        failureCallback(error);
      });
    },
    initExternalDraggable: function initExternalDraggable() {
      var _this = this;

      var containerEl = document.getElementById("external-draggable");

      if (_this.draggableEvent) {
        _this.draggableEvent.destroy();
      }

      _this.$nextTick(function () {
        _this.draggableEvent = new Draggable(containerEl, {
          itemSelector: ".fc-event",
          eventData: function eventData(eventEl) {
            var attribute = eventEl.dataset;

            if (attribute && attribute.event) {
              _this.dragged = JSON.parse(attribute.event);
            }

            return {
              title: eventEl.innerText
            };
          }
        });
      });
    },
    dateChanged: function dateChanged(_ref3) {
      var startStr = _ref3.startStr,
          endStr = _ref3.endStr;
      this.startDate = startStr.substr(0, 19);
      this.endDate = endStr.substr(0, 19);
      this.getUnassignedVisits();
    },
    getUnassignedVisits: function getUnassignedVisits() {
      var _this4 = this;

      var dates = {
        start: moment(this.startDate).format("YYYY-MM-DD"),
        end: moment(this.endDate).format("YYYY-MM-DD"),
        customers: this.customer
      };
      APIService.query("resource-calendar/un-assigned-visits", dates).then(function (_ref4) {
        var data = _ref4.data;
        _this4.visit = data.data;

        if (_this4.visit.length) {
          setTimeout(_this4.initExternalDraggable, 200);
        }
      }).catch(function (error) {
        _this4.logError(error);
      });
    },
    dropped: function dropped(param) {
      var _this5 = this;

      try {
        var event = this.dragged;

        if (isEmpty(event)) {
          this.getUnassignedVisits();
          this.removeAllEvents();
          this.$nextTick(function () {
            _this5.refetchEvents();
          });
          return false;
        }

        var is_all_day = toSafeInteger(event.all_day);
        var resource = param.resource;
        var params = {
          all_day: is_all_day,
          date: is_all_day ? event.started_at : null,
          visit: event.id,
          engineer: resource.id,
          del_engineer: 0,
          started_at: is_all_day ? null : event.started_at,
          finished_at: is_all_day ? null : event.finished_at
        };
        APIService.patch("resource-calendar/event-dropped", params).then(function () {
          _this5.dragged = {};

          _this5.getUnassignedVisits();
        }).catch(function (error) {
          _this5.logError(error);
        }).finally(function () {
          _this5.removeAllEvents();

          _this5.$nextTick(function () {
            _this5.refetchEvents();
          });
        });
      } catch (error) {
        this.logError(error);
      }
    },
    initFilter: function initFilter() {
      var _this6 = this;

      APIService.query("resource-calendar/filters").then(function (_ref5) {
        var data = _ref5.data;
        _this6.customerList = data.customers;
        _this6.engineerList = data.engineers;
        _this6.statusList = data.statuses;
      }).catch(function (error) {
        _this6.logError(error);
      });
    }
  },
  mounted: function mounted() {
    this.initCalendar();
    this.initFilter();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Resource Calendar"
    }]);
  }
};